import React, {useState} from 'react';
import {Link, NavLink, Route, Switch} from 'react-router-dom';

import GamesPage from './pages/games/GamesPage';
import TeamRanksPage from './pages/team-ranks/TeamRanksPage';
import TeamDetailPage from './pages/team-detail/TeamDetailPage';
import PayoffCalculatorPage from './pages/payoff-calculator/PayoffCalculatorPage';

import parlayproimg from './images/parlaypro2.png';

const activeStyle = {
  fontWeight: 'bold',
};

export default function App(props) {
  const [isActive, setIsActive] = useState(false);
  return (
    <div>
      <header>
        <nav
          className='navbar is-white'
          role='navigation'
          aria-label='main navigation'>
          <div className='navbar-brand'>
            <Link className='navbar-item' to='/'>
              <img
                className='header__logo'
                src={parlayproimg}
                alt='Parlay Pro'
              />
            </Link>
            <a
              role='button'
              className={`navbar-burger burger ${isActive && 'is-active'}`}
              aria-label='menu'
              aria-expanded='false'
              datatarget='navbarMenu'
              onClick={() => setIsActive(!isActive)}>
              <span aria-hidden='true'></span>
              <span aria-hidden='true'></span>
              <span aria-hidden='true'></span>
            </a>
          </div>
          <div
            id='navbarMenu'
            className={`navbar-menu ${isActive && 'is-active'}`}>
            <div className='navbar-start'>
              <NavLink
                onClick={() => setIsActive(!isActive)}
                aria-label='Games'
                role='nav'
                className='navbar-item'
                activeStyle={activeStyle}
                to='/'
                exact>
                Games
              </NavLink>
              <NavLink
                onClick={() => setIsActive(!isActive)}
                aria-label='Teams'
                role='nav'
                className='navbar-item'
                activeStyle={activeStyle}
                to='/teams'>
                Teams
              </NavLink>
              <NavLink
                onClick={() => setIsActive(!isActive)}
                aria-label='Ranks'
                role='nav'
                className='navbar-item'
                activeStyle={activeStyle}
                to='/ranks'>
                Ranks
              </NavLink>
              <NavLink
                onClick={() => setIsActive(!isActive)}
                aria-label='Payoffs'
                role='nav'
                className='navbar-item'
                activeStyle={activeStyle}
                to='/payoffs'>
                Payoffs
              </NavLink>
            </div>
          </div>
        </nav>
      </header>
      <section className='section has-background-light has-min-height-200vh'>
        <Switch>
          <Route exact path='/' component={GamesPage} />
          <Route exact path='/teams' component={TeamDetailPage} />
          <Route path='/teams/:teamId' component={TeamDetailPage} />
          <Route path='/ranks' component={TeamRanksPage} />
          <Route path='/payoffs' component={PayoffCalculatorPage} />
        </Switch>
      </section>
    </div>
  );
}
