/* eslint camelcase: "off"*/
import _ from 'lodash';
import axios from 'axios';

import * as utils from './utils';

const PROD_URL = 'https://api.parlaypro.io';
// const STAG_URL = 'https://staging-api.parlaypro.io';
const DEV_LIVE_URL = 'http://localhost:8000';
const DEV_MOCK_URL = 'http://localhost:4000';

const API_URL = (function __getApiUrl() {
  if (process.env.NODE_ENV === 'production' || process.env.REACT_APP_PROD_API) {
    // if (process.env.REACT_APP_STAGING) {
    //   return new URL(STAG_URL);
    // }
    return new URL(PROD_URL);
  } else if (process.env.NODE_ENV === 'development') {
    if (process.env.REACT_APP_REAL_API) {
      return new URL(DEV_LIVE_URL);
    }
    return new URL(DEV_MOCK_URL);
  }
  return null;
})();

function sleep(s) {
  return new Promise((resolve) => {
    return setTimeout(resolve, s * 1000);
  });
}

function barf(error) {
  console.log(error);
  return {error};
}

export async function wrap(data) {
  await sleep(0.5);
  return {
    data: utils.transformObjectKeys(_.camelCase)(data),
  };
}

function urlFor(fragment) {
  if (fragment.startsWith('/')) {
    fragment = fragment.slice(1);
  }
  return new URL(fragment, API_URL).toString();
}

export async function getSeasons() {
  try {
    const resp = await axios.get(urlFor('/seasons'));
    return wrap(resp.data);
  } catch (error) {
    // log
    return barf(error);
  }
}

export async function getWeeksInSeason(season) {
  try {
    if (!season) {
      return barf('Invalid parameters.');
    }
    const resp = await axios.get(urlFor('/weeks'), {
      params: {season, only_completed: false},
    });
    return wrap(resp.data);
  } catch (error) {
    // log
    return barf(error);
  }
}

export async function getCompletedWeeksInSeason(season) {
  try {
    if (!season) {
      return barf('Invalid parameters.');
    }
    const resp = await axios.get(urlFor('/weeks'), {
      params: {season, only_completed: true},
    });
    return wrap(resp.data);
  } catch (error) {
    // log
    return barf(error);
  }
}

export async function getTeamsForSeason(season) {
  try {
    if (!season) {
      return barf('Invalid parameters.');
    }
    const resp = await axios.get(urlFor('/teams'), {
      params: {season},
    });
    return wrap(resp.data);
  } catch (error) {
    // log
    return barf(error);
  }
}

export async function getTeamDetail({
  teamId,
  season,
  startWeek: start_week,
  endWeek: end_week,
}) {
  try {
    if (!teamId || !season || !start_week || !end_week) {
      return barf('Undefined parameters.');
    }
    const resp = await axios.get(urlFor(`/teams/${teamId}`), {
      params: {season, start_week, end_week},
    });
    return wrap(resp.data);
  } catch (error) {
    return barf(error);
  }
}

export async function getTeamPointDifferenceRanks({
  season,
  startWeek: start_week,
  endWeek: end_week,
}) {
  try {
    const resp = await axios.get(urlFor('/teams/ranks/point-difference'), {
      params: {season, start_week, end_week},
    });
    return wrap(resp.data);
  } catch (error) {
    return barf(error);
  }
}

export async function getTeamPointTotalRanks({
  season,
  startWeek: start_week,
  endWeek: end_week,
}) {
  try {
    const resp = await axios.get(urlFor('/teams/ranks/point-total'), {
      params: {season, start_week, end_week},
    });
    return wrap(resp.data);
  } catch (error) {
    return barf(error);
  }
}

export async function getParlayTeamSpreadMarginRanks({
  season,
  type,
  startWeek: start_week,
  endWeek: end_week,
}) {
  try {
    const url = urlFor('/teams/ranks/parlay-team-spread-margin');
    const resp = await axios.get(url, {
      params: {season, start_week, end_week, type},
    });
    return wrap(resp.data);
  } catch (error) {
    return barf(error);
  }
}

export async function getParlayGameSpreadMarginRanks({
  season,
  type,
  startWeek: start_week,
  endWeek: end_week,
  gameSpreadType: game_spread_type,
}) {
  try {
    const url = urlFor('/teams/ranks/parlay-game-spread-margin');
    const resp = await axios.get(url, {
      params: {season, start_week, end_week, type, game_spread_type},
    });
    return wrap(resp.data);
  } catch (error) {
    return barf(error);
  }
}

export async function getParlayTeamSpreadCoverRanks({
  season,
  type,
  startWeek: start_week,
  endWeek: end_week,
}) {
  try {
    const url = urlFor('/teams/ranks/parlay-team-spread-cover');
    const resp = await axios.get(url, {
      params: {season, start_week, end_week, type},
    });
    return wrap(resp.data);
  } catch (error) {
    return barf(error);
  }
}

export async function getParlayGameSpreadCoverRanks({
  season,
  type,
  startWeek: start_week,
  endWeek: end_week,
  gameSpreadType: game_spread_type,
}) {
  try {
    const url = urlFor('/teams/ranks/parlay-game-spread-cover');
    const resp = await axios.get(url, {
      params: {season, start_week, end_week, type, game_spread_type},
    });
    return wrap(resp.data);
  } catch (error) {
    return barf(error);
  }
}

export async function getGamesForWeekInSeason({week, season}) {
  if (!week || !season) {
    return barf('Undefined parameters.');
  }
  const url = urlFor('/games');
  try {
    const resp = await axios.get(url, {
      params: {season, week},
    });
    return wrap(resp.data);
  } catch (error) {
    return barf(error);
  }
}

export async function getParlayTeamSpreadCover({week, season}) {
  if (!season) {
    return barf('Undefined parameters.');
  }
  const url = urlFor('/parlay-team-spread-cover');
  const params = week ? {season, week} : {season};
  try {
    const resp = await axios.get(url, {params});
    return wrap(resp.data);
  } catch (error) {
    return barf(error);
  }
}

export async function getParlayGameSpreadCover({
  week,
  season,
  gameSpreadType: game_spread_type,
}) {
  if (!season) {
    return barf('Undefined parameters.');
  }
  const url = urlFor('/parlay-game-spread-cover');
  const periodParams = week ? {season, week} : {season};
  const params = {
    ...periodParams,
    game_spread_type,
  };
  try {
    const resp = await axios.get(url, {params});
    return wrap(resp.data);
  } catch (error) {
    return barf(error);
  }
}
