import _ from 'lodash';
import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';

import * as api from '../../api';
import {
  Card,
  CardContent,
  CardFooter,
  CardFooterItem,
  CardHeader,
} from '../../common';
import {buildArray, toQueryString} from '../../utils';

const RANK_CARD_DATA_TEST_ID = 'rank-card';

function RankListRow({item, queryString}) {
  return (
    <tr data-testid='rank-list-row'>
      <td>
        {item.rank + '.  '}
        <Link
          className='has-text-primary'
          to={{pathname: `/teams/${item.teamId}`, search: queryString}}>
          {item.teamShortName}
        </Link>
      </td>
      <td className='has-text-right pr-0'>{item.value.toFixed(2)}</td>
      <td className='has-text-right has-text-grey-light' style={{width: 0}}>
        <span> (n={item.observations})</span>
      </td>
    </tr>
  );
}

function SampleRanks(props) {
  const teamLinkQueryString = toQueryString(
    _.pick(props.args, ['season', 'startWeek', 'endWeek']),
  );
  const viewAllQueryString = toQueryString(props.args);
  const rankRows = props.ranks.map((item) => (
    <RankListRow
      key={item.teamId + '-' + item.rank}
      item={item}
      queryString={teamLinkQueryString}
    />
  ));
  return (
    <div className='column is-one-quarter-widescreen is-one-third-desktop is-half-tablet'>
      <Card data-testid={RANK_CARD_DATA_TEST_ID} title={props.title}>
        <CardHeader>{props.title}</CardHeader>
        <CardContent>
          <table className='table is-striped is-fullwidth'>
            <tbody>{rankRows}</tbody>
          </table>
        </CardContent>
        <CardFooter>
          <CardFooterItem>
            <Link
              className='has-text-primary'
              to={{pathname: props.urlPath, search: viewAllQueryString}}>
              View All
            </Link>
          </CardFooterItem>
        </CardFooter>
      </Card>
    </div>
  );
}

function AllRanks(props) {
  const queryString = toQueryString(
    _.pick(props.args, ['season', 'startWeek', 'endWeek']),
  );
  const rankRows = props.ranks.map((item) => (
    <RankListRow
      key={item.teamId + '-' + item.rank}
      item={item}
      queryString={queryString}
    />
  ));
  return (
    <Card data-testid={RANK_CARD_DATA_TEST_ID} title={props.title}>
      <CardHeader>{props.title}</CardHeader>
      <CardContent>
        <div className='columns'>
          <div className='column ranks-table-container'>
            <table className='table is-striped is-fullwidth'>
              <tbody>{rankRows.slice(0, 16)}</tbody>
            </table>
          </div>
          <div className='column ranks-table-container'>
            <table className='table is-striped is-fullwidth'>
              <tbody>{rankRows.slice(16)}</tbody>
            </table>
          </div>
        </div>
      </CardContent>
      <CardFooter>
        <CardFooterItem>
          <Link
            className='has-text-primary'
            to={{pathname: '/ranks', search: queryString}}>
            Back to Ranks
          </Link>
        </CardFooterItem>
      </CardFooter>
    </Card>
  );
}

function Ranks(props) {
  const [teamRanks, setTeamRanks] = useState(
    buildArray(32, (x, idx) => ({
      teamId: 0,
      teamName: '',
      rank: idx + 1,
      value: 0.0,
    })),
  );

  useEffect(() => {
    let isMounted = true;
    async function updateRanks() {
      if (Object.values(props.args).some(_.isNil)) {
        return;
      }
      const rankingsResp = await props.apiCall({...props.args});
      if (isMounted) {
        setTeamRanks(rankingsResp.data);
      }
    }
    updateRanks();
    return () => {
      isMounted = false;
    };
  }, [props]);

  return props.limit ? (
    <SampleRanks
      ranks={teamRanks.slice(0, props.limit)}
      title={props.title}
      args={props.args}
      urlPath={props.urlPath}
    />
  ) : (
    <AllRanks ranks={teamRanks} title={props.title} args={props.args} />
  );
}

export function TeamPointDifferenceRanks(props) {
  const args = {
    season: props.season,
    startWeek: props.startWeek,
    endWeek: props.endWeek,
  };
  return (
    <Ranks
      title='Point Difference'
      urlPath='/ranks/point-difference'
      apiCall={api.getTeamPointDifferenceRanks}
      args={args}
      {...props}
    />
  );
}

export function TeamPointTotalRanks(props) {
  const args = {
    season: props.season,
    startWeek: props.startWeek,
    endWeek: props.endWeek,
  };
  return (
    <Ranks
      title='Point Total'
      urlPath='/ranks/point-total'
      apiCall={api.getTeamPointTotalRanks}
      args={args}
      {...props}
    />
  );
}

export function ParlayGameSpreadMarginRanks(props) {
  const args = {
    season: props.season,
    startWeek: props.startWeek,
    endWeek: props.endWeek,
    type: _.snakeCase(props.type),
    gameSpreadType: props.gameSpreadType,
  };
  const title =
    _.startCase(props.type.replace('_', ' ')) +
    ' ' +
    _.startCase(props.gameSpreadType) +
    ' Margin';
  return (
    <Ranks
      title={title}
      urlPath='/ranks/parlay-game-spread-margin'
      apiCall={api.getParlayGameSpreadMarginRanks}
      args={args}
      {...props}
    />
  );
}

export function ParlayTeamSpreadMarginRanks(props) {
  const args = {
    season: props.season,
    startWeek: props.startWeek,
    endWeek: props.endWeek,
    type: _.snakeCase(props.type),
  };
  const title = _.startCase(props.type.replace('_', ' ')) + ' Spread Margin';
  return (
    <Ranks
      title={title}
      urlPath='/ranks/parlay-team-spread-margin'
      apiCall={api.getParlayTeamSpreadMarginRanks}
      args={args}
      {...props}
    />
  );
}

export function ParlayTeamSpreadCoverRanks(props) {
  const args = {
    season: props.season,
    startWeek: props.startWeek,
    endWeek: props.endWeek,
    type: _.snakeCase(props.type),
  };
  const title = _.startCase(props.type.replace('_', ' ')) + ' Spread Cover';
  return (
    <Ranks
      title={title}
      urlPath='/ranks/parlay-team-spread-cover'
      apiCall={api.getParlayTeamSpreadCoverRanks}
      args={args}
      {...props}
    />
  );
}

export function ParlayGameSpreadCoverRanks(props) {
  const args = {
    season: props.season,
    startWeek: props.startWeek,
    endWeek: props.endWeek,
    type: _.snakeCase(props.type),
    gameSpreadType: props.gameSpreadType,
  };
  const title =
    _.startCase(props.type.replace('_', ' ')) +
    ' ' +
    _.startCase(props.gameSpreadType) +
    ' Cover';
  return (
    <Ranks
      title={title}
      urlPath='/ranks/parlay-game-spread-cover'
      apiCall={api.getParlayGameSpreadCoverRanks}
      args={args}
      {...props}
    />
  );
}
