import React, {useEffect, useState} from 'react';
import {Card, CardContent, CardHeader} from '../../common';
import {HorizontalBar} from 'react-chartjs-2';

import {getParlayTeamSpreadCover, getParlayGameSpreadCover} from '../../api';
import {mapValues, merge, omit, round, startCase} from 'lodash';

const chartOptions = {
  legend: {
    position: 'bottom',
    align: 'center',
  },
  scales: {
    yAxes: [{
      gridLines: false,
      ticks: {
        padding: 10,
      },
    }],
    xAxes: [
      {
        gridLines: false,
        ticks: {
          min: 0,
          max: 1,
          stepSize: 0.5,
        },
      },
    ],
  },
};

const __formatCoverPct = (key) => ({covered, observations}) => {
  return {
    [key]: round(covered / observations, 2),
  };
};

const defaultParlayCoverData = {
  regular: {
    season: 0.0,
    week: 0.0,
  },
  teaser: {
    season: 0.0,
    week: 0.0,
  },
  superTeaser: {
    season: 0.0,
    week: 0.0,
  },
  reverseTeaser: {
    season: 0.0,
    week: 0.0,
  },
};

export function TeamSpreadCover({season, week}) {
  const [data, setData] = useState(defaultParlayCoverData);

  useEffect(() => {
    async function fetchParlayTeamSpreadCover() {
      const weekResp = await getParlayTeamSpreadCover({season, week});
      const seasonResp = await getParlayTeamSpreadCover({season});
      setData(
        merge(
          mapValues(weekResp.data, __formatCoverPct('week')),
          mapValues(seasonResp.data, __formatCoverPct('season')),
        ),
      );
    }
    fetchParlayTeamSpreadCover();
  }, [season, week]);

  const chartData = {
    labels: Object.keys(data).map(startCase),
    datasets: [
      {
        stack: 'Week',
        label: 'Week',
        backgroundColor: '#00a78a',
        hidden: !Object.values(mapValues(data, (x) => x.week)).some((x) => x),
        data: Object.values(mapValues(data, (x) => x.week)),
      },
      {
        stack: 'Season',
        label: 'Season',
        backgroundColor: '#005741',
        data: Object.values(mapValues(data, (x) => x.season)),
      },
    ],
  };

  return (
    <Card>
      <CardHeader>Spread Cover %</CardHeader>
      <CardContent>
        <HorizontalBar data={chartData} options={chartOptions} />
      </CardContent>
    </Card>
  );
}

export function GameSpreadCover({season, week, gameSpreadType}) {
  const [data, setData] = useState(
    omit(defaultParlayCoverData, 'reverseTeaser'),
  );

  useEffect(() => {
    async function fetchParlayGameSpreadCover() {
      const weekResp = await getParlayGameSpreadCover({
        season,
        week,
        gameSpreadType,
      });
      const seasonResp = await getParlayGameSpreadCover({
        season,
        gameSpreadType,
      });
      setData(
        merge(
          mapValues(weekResp.data, __formatCoverPct('week')),
          mapValues(seasonResp.data, __formatCoverPct('season')),
        ),
      );
    }
    fetchParlayGameSpreadCover();
  }, [season, week, gameSpreadType]);

  const chartData = {
    labels: Object.keys(data).map(startCase),
    datasets: [
      {
        stack: 'Week',
        label: 'Week',
        backgroundColor: '#00a78a',
        hidden: !Object.values(mapValues(data, (x) => x.week)).some((x) => x),
        data: Object.values(mapValues(data, (x) => x.week)),
      },
      {
        stack: 'Season',
        label: 'Season',
        backgroundColor: '#005741',
        data: Object.values(mapValues(data, (x) => x.season)),
      },
    ],
  };

  return (
    <Card>
      <CardHeader>{`${startCase(gameSpreadType)} Cover %`}</CardHeader>
      <CardContent>
        <HorizontalBar data={chartData} options={chartOptions} />
      </CardContent>
    </Card>
  );
}
