import _ from 'lodash';
import React from 'react';
import qs from 'qs';

export function toQueryString(obj) {
  const queryArgsObj = _.mapKeys(obj, (value, key) => _.snakeCase(key));
  return '?' + qs.stringify(queryArgsObj);
}

export function fromQueryString(str) {
  if (str.startsWith('?')) {
    str = str.slice(1);
  }
  const queryArgsObj = qs.parse(str);
  return _.mapKeys(queryArgsObj, (value, key) => _.camelCase(key));
}

export function currency(value) {
  return value.toFixed(2).replace(/^(-)?/, '$1$');
}

export function decimal(value) {
  return value.toFixed(2);
}

export function* cartesianPairs(array1, array2) {
  for (const x1 of array1) {
    for (const x2 of array2) {
      yield [x1, x2];
    }
  }
}

export function removeFirst(array, value) {
  const arrayCopy = [...array];
  arrayCopy.splice(arrayCopy.indexOf(value), 1);
  return arrayCopy;
}

export function transformObjectKeys(transformFn) {
  function transform(arg) {
    if (_.isPlainObject(arg)) {
      for (const [key, value] of Object.entries(arg)) {
        arg = _.omit(arg, key);
        arg[transformFn(key)] = transform(value);
      }
      return arg;
    } else if (_.isArray(arg)) {
      for (const [idx, value] of arg.entries()) {
        arg[idx] = transform(value);
      }
      return arg;
    } else {
      return arg;
    }
  }
  return transform;
}

export function makeOption(value) {
  return (
    <option key={value} value={value}>
      {value}
    </option>
  );
}

export function buildArray(n, callback) {
  return Array.from(new Array(n), callback);
}
