import React from 'react';

import {SelectField, SubmitButtonField} from '../../common';
import {makeOption} from '../../utils';

export default function TeamRanksForm(props) {
  const seasonOptions = props.seasons.map(makeOption);
  const startWeekOptions = props.weeks.map(makeOption);
  const endWeekOptions = props.weeks.map(makeOption);
  return (
    <form onSubmit={props.onSubmit}>
      <div className='field is-horizontal'>
        <div className='field-body'>
          <SelectField
            id='season'
            label='Season'
            value={props.season}
            onChange={props.onSeasonChange}>
            {seasonOptions}
          </SelectField>
          <SelectField
            id='start-week'
            label='Start Week'
            value={props.startWeek}
            onChange={props.onStartWeekChange}>
            {startWeekOptions}
          </SelectField>
          <SelectField
            id='end-week'
            label='End Week'
            value={props.endWeek}
            onChange={props.onEndWeekChange}>
            {endWeekOptions}
          </SelectField>
          <SubmitButtonField text='Go' />
        </div>
      </div>
    </form>
  );
}
