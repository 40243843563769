import ordinal from 'ordinal';
import React from 'react';

import {Card, CardContent, CardHeader} from '../../common';

export default function PointSummary({pointDifference, pointTotal}) {
  return (
    <Card title='Point Summary'>
      <CardHeader>Point Summary</CardHeader>
      <CardContent>
        <div className='table-container'>
          <table className='table is-striped is-fullwidth'>
            <thead>
              <tr>
                <th></th>
                <th>Point Difference</th>
                <th>Point Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th className='has-text-left'>Average</th>
                <td>
                  {pointDifference.average} ({ordinal(pointDifference.averageRank)})
                </td>
                <td>
                  {pointTotal.average} ({ordinal(pointTotal.averageRank)})
                </td>
              </tr>
              <tr>
                <th className='has-text-left'>Standard Deviation</th>
                <td>{pointDifference.stdev}</td>
                <td>{pointTotal.stdev}</td>
              </tr>
              <tr>
                <th className='has-text-left'>Minimum</th>
                <td>{pointDifference.min}</td>
                <td>{pointTotal.min}</td>
              </tr>
              <tr>
                <th className='has-text-left'>Maximum</th>
                <td>{pointDifference.max}</td>
                <td>{pointTotal.max}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </CardContent>
    </Card>
  );
}
