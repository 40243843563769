import _ from 'lodash';
import React from 'react';
import {Link} from 'react-router-dom';

import {Card, CardContent, CardHeader} from '../../common';
import {toQueryString} from '../../utils';

import {getOutcome} from './utils';

function __createResultMessage(result) {
  const {oppTeamPoints, teamPoints} = result;
  const outcome = getOutcome(result);
  return `${_.capitalize(outcome)} ${teamPoints} - ${oppTeamPoints}`;
}

export default function TeamResults({results, season, startWeek, endWeek}) {
  const search = toQueryString({season, startWeek, endWeek});
  const resultRows = results.map((result, idx) => (
    <tr key={idx}>
      <td>{`Week ${result.gameWeek}:`}</td>
      <td className='has-text-centered'>{__createResultMessage(result)}</td>
      <td className='has-text-centered'>{result.teamIsHome ? 'vs' : 'at'}</td>
      <td className='has-text-left'>
        <Link
          className='has-text-primary'
          to={{pathname: `/teams/${result.oppTeamId}`, search}}>
          {result.oppTeamShortName}
        </Link>
      </td>
    </tr>
  ));
  return (
    <Card title='Team Results'>
      <CardHeader>Team Results</CardHeader>
      <CardContent>
        <div className='table-container'>
          <table className='table is-striped is-fullwidth'>
            <tbody>{resultRows}</tbody>
          </table>
        </div>
      </CardContent>
    </Card>
  );
}
