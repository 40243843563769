import React, {useState} from 'react';
import {DollarValueField, PageContainer, Card, CardContent, CardHeader} from '../../common';
import {buildArray} from '../../utils';

function picksToOddsReducerFn(acc, val) {
  return {
    ...acc,
    [val.picks]: val.odds,
  };
}

const parlayTypeOdds = {
  regular: [
    {picks: 3, odds: 6.5},
    {picks: 4, odds: 11},
    {picks: 5, odds: 20},
    {picks: 6, odds: 40},
    {picks: 7, odds: 75},
    {picks: 8, odds: 150},
    {picks: 9, odds: 375},
    {picks: 10, odds: 800},
    {picks: 11, odds: 1400},
    {picks: 12, odds: 2500},
  ].reduce(picksToOddsReducerFn, {}),
  teaser: [
    {picks: 3, odds: 12 / 5},
    {picks: 4, odds: 7 / 2},
    {picks: 5, odds: 9 / 2},
    {picks: 6, odds: 7},
    {picks: 7, odds: 10},
    {picks: 8, odds: 15},
    {picks: 9, odds: 22},
    {picks: 10, odds: 30},
    {picks: 11, odds: 45},
    {picks: 12, odds: 65},
    {picks: 13, odds: 100},
    {picks: 14, odds: 150},
    {picks: 15, odds: 200},
    {picks: 16, odds: 275},
    {picks: 17, odds: 375},
    {picks: 18, odds: 500},
    {picks: 19, odds: 750},
    {picks: 20, odds: 1000},
  ].reduce(picksToOddsReducerFn, {}),
  superTeaser: [
    {picks: 4, odds: 11 / 5},
    {picks: 5, odds: 13 / 5},
    {picks: 6, odds: 3},
    {picks: 7, odds: 4},
    {picks: 8, odds: 11 / 2},
    {picks: 9, odds: 7},
    {picks: 10, odds: 9},
    {picks: 11, odds: 11},
    {picks: 12, odds: 14},
    {picks: 13, odds: 18},
    {picks: 14, odds: 23},
    {picks: 15, odds: 30},
    {picks: 16, odds: 37},
    {picks: 17, odds: 45},
    {picks: 18, odds: 55},
    {picks: 19, odds: 65},
    {picks: 20, odds: 80},
  ].reduce(picksToOddsReducerFn, {}),
  reverseTeaser: [
    {picks: 3, odds: 20},
    {picks: 4, odds: 60},
    {picks: 5, odds: 200},
    {picks: 6, odds: 600},
    {picks: 7, odds: 1800},
    {picks: 8, odds: 5000},
  ].reduce(picksToOddsReducerFn, {}),
};

function PayoffTable(props) {
  const getPayoff = (parlayType, numPicks) => {
    const odds = parlayTypeOdds[parlayType][numPicks];
    if (!odds) {
      return 'N/A';
    }
    return `$${parseFloat(odds * props.wager).toFixed(2)}`;
  };
  return (
    <div className='table-container'>
      <table className='table is-fullwidth is-hoverable'>
        <thead>
          <tr>
            <th>Number of Picks</th>
            <th>Regular</th>
            <th>Teaser</th>
            <th>Super Teaser</th>
            <th>Reverse Teaser</th>
          </tr>
        </thead>
        <tbody>
          {buildArray(18, (x, idx) => idx + 3).map(elem => (
            <tr key={elem}>
              <td>{elem}</td>
              <td>{getPayoff('regular', elem)}</td>
              <td>{getPayoff('teaser', elem)}</td>
              <td>{getPayoff('superTeaser', elem)}</td>
              <td>{getPayoff('reverseTeaser', elem)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default function PayoffCalculatorPage() {
  const [wager, setWager] = useState(1);

  const handleWagerChange = (e) => {
    const nextWager = parseInt(e.target.value) || 0;
    setWager(nextWager);
  };

  return (
    <div className='container'>
      <div className='level'>
        <div className='level-left'>
          <form>
            <div className='field is-horizontal'>
              <div className='field-body'>
                <DollarValueField
                  label='Wager'
                  value={wager}
                  onChange={handleWagerChange}>
                  {wager}
                </DollarValueField>
              </div>
            </div>
          </form>
        </div>
      </div>
      <PageContainer>
        <Card title='Payoff Table'>
          <CardHeader>Payoff Table</CardHeader>
          <CardContent>
            <PayoffTable wager={wager} />
          </CardContent>
        </Card>
      </PageContainer>
    </div>
  );
}
