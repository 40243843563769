import React, {useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';

import {
  getGamesForWeekInSeason,
  getSeasons,
  getWeeksInSeason,
} from '../../api';
import {LoadingIndicator, PageContainer} from '../../common';
import {toQueryString, fromQueryString} from '../../utils';

import GamesForm from './GamesForm';
import GameResults from './GameResults';
import {GameSpreadCover, TeamSpreadCover} from './SpreadCover';

export default function GamesPage(props) {
  // order of these matter!
  const location = useLocation();
  const history = useHistory();
  const [periodData, setPeriodData] = useState({
    season: undefined,
    seasons: [],
    week: undefined,
    weeks: [],
  });
  const [gameResults, setGameResults] = useState({
    results: [],
  });
  const [isLoading, setIsLoading] = useState(true);
  // const [error, setIsError] = useState({});

  // init effect
  useEffect(() => {
    async function init() {
      setIsLoading(true);
      const search = fromQueryString(location.search);
      // unrelated: perhaps make api return seasons + weeks in one call?
      const nextSeasons = (await getSeasons()).data;
      let nextSeason; let nextWeek; let nextWeeks;

      // check location for existing season/week combo if none, set defaults
      if (nextSeasons.includes(parseInt(search.season))) {
        nextSeason = parseInt(search.season);
        nextWeeks = (await getWeeksInSeason(nextSeason)).data;
        if (nextWeeks.includes(parseInt(search.week))) {
          // got season, got week
          nextWeek = parseInt(search.week);
        } else {
          // got season, no week
          nextWeek = nextWeeks[nextWeeks.length - 1];
        }
      } else {
        // no season, no week
        nextSeason = nextSeasons[0];
        nextWeeks = (await getWeeksInSeason(nextSeason)).data;
        nextWeek = nextWeeks[nextWeeks.length - 1];
      }
      const nextGameResults = (
        await getGamesForWeekInSeason({
          week: nextWeek,
          season: nextSeason,
        })
      ).data;

      // call setters
      setPeriodData({
        seasons: nextSeasons,
        season: nextSeason,
        weeks: nextWeeks,
        week: nextWeek,
      });
      setGameResults({
        results: nextGameResults,
        week: nextWeek,
        season: nextSeason,
      });
      setIsLoading(false);
    }
    init();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // location change effect
  useEffect(() => {
    async function update() {
      setIsLoading(true);
      const search = fromQueryString(location.search);

      if (periodData.seasons.includes(parseInt(search.season))) {
        const nextSeason = parseInt(search.season);
        const nextWeeks = (await getWeeksInSeason(nextSeason)).data;
        let nextWeek;

        if (nextWeeks.includes(parseInt(search.week))) {
          nextWeek = parseInt(search.week);
        } else {
          nextWeek = nextWeeks[nextWeeks.length - 1];
        }

        const nextGameResults = (
          await getGamesForWeekInSeason({
            week: nextWeek,
            season: nextSeason,
          })
        ).data;

        setPeriodData({
          ...periodData,
          season: nextSeason,
          weeks: nextWeeks,
          week: nextWeek,
        });
        setGameResults({
          results: nextGameResults,
          week: nextWeek,
          season: nextSeason,
        });
      }
      setIsLoading(false);
    }
    update();
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  // form input change handlers
  async function onSeasonChange(e) {
    const nextSeason = parseInt(e.target.value);
    const nextWeeks = (await getWeeksInSeason(nextSeason)).data;
    const nextWeek = nextWeeks[nextWeeks.length - 1];

    setPeriodData({
      ...periodData,
      season: nextSeason,
      weeks: nextWeeks,
      week: nextWeek,
    });
  }

  function onWeekChange(e) {
    const nextWeek = parseInt(e.target.value);
    setPeriodData({
      ...periodData,
      week: nextWeek,
    });
  }

  // form submit handler
  function onSubmit(e) {
    e.preventDefault();
    const {pathname} = history.location;
    const nextQueryString = toQueryString({
      season: periodData.season,
      week: periodData.week,
    });
    const nextLocation = `${pathname}${nextQueryString}`;
    history.push(nextLocation);
  }

  return (
    <div className='container'>
      <div className='level'>
        <div className='level-left'>
          <GamesForm
            {...periodData}
            onSeasonChange={onSeasonChange}
            onWeekChange={onWeekChange}
            onSubmit={onSubmit}
          />
        </div>
      </div>
      {!gameResults.season || !gameResults.week || isLoading ? (
        <LoadingIndicator />
      ) : (
        <PageContainer
          title={`Week ${gameResults.week}, ${gameResults.season}`}>
          <div className='columns is-multiline'>
            <div className='column is-third'>
              <TeamSpreadCover season={gameResults.season} week={gameResults.week} />
            </div>
            <div className='column is-third'>
              <GameSpreadCover
                season={gameResults.season}
                week={gameResults.week}
                gameSpreadType='over'/>
            </div>
            <div className='column is-third'>
              <GameSpreadCover
                season={gameResults.season}
                week={gameResults.week}
                gameSpreadType='under'/>
            </div>
            <div className='column is-full'>
              <GameResults gameResults={gameResults} />
            </div>
          </div>
        </PageContainer>
      )}
    </div>
  );
}
