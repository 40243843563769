import React from 'react';

import {PARLAY_TYPES, TYPE_PAIRS} from '../../constants';
import * as Ranks from './Ranks';

const RANK_LIST_LIMIT = 5;

export default function TeamRanksGrid(props) {
  const {season, startWeek, endWeek} = props;

  return (
    <div className='columns is-multiline'>
      <Ranks.TeamPointDifferenceRanks
        season={season}
        startWeek={startWeek}
        endWeek={endWeek}
        limit={RANK_LIST_LIMIT}
      />
      <Ranks.TeamPointTotalRanks
        season={season}
        startWeek={startWeek}
        endWeek={endWeek}
        limit={RANK_LIST_LIMIT}
      />
      {PARLAY_TYPES.map((type) => (
        <React.Fragment key={`${type}`}>
          <Ranks.ParlayTeamSpreadMarginRanks
            key={`${type}-margin`}
            type={type}
            season={season}
            startWeek={startWeek}
            endWeek={endWeek}
            limit={RANK_LIST_LIMIT}
          />
          <Ranks.ParlayTeamSpreadCoverRanks
            key={`${type}-cover`}
            type={type}
            season={season}
            startWeek={startWeek}
            endWeek={endWeek}
            limit={RANK_LIST_LIMIT}
          />
        </React.Fragment>
      ))}
      {TYPE_PAIRS.map(([type, gameSpreadType]) => (
        <React.Fragment key={`${type}-${gameSpreadType}`}>
          <Ranks.ParlayGameSpreadMarginRanks
            key={`${type}-${gameSpreadType}-margin`}
            type={type}
            gameSpreadType={gameSpreadType}
            season={season}
            startWeek={startWeek}
            endWeek={endWeek}
            limit={RANK_LIST_LIMIT}
          />
          <Ranks.ParlayGameSpreadCoverRanks
            key={`${type}-${gameSpreadType}-cover`}
            type={type}
            gameSpreadType={gameSpreadType}
            season={season}
            startWeek={startWeek}
            endWeek={endWeek}
            limit={RANK_LIST_LIMIT}
          />
        </React.Fragment>
      ))}
    </div>
  );
}
