import React, {useState, useEffect} from 'react';
import {useHistory, useLocation, useParams} from 'react-router-dom';

import {
  getCompletedWeeksInSeason,
  getSeasons,
  getTeamsForSeason,
  getTeamDetail,
} from '../../api';
import {LoadingIndicator, PageContainer} from '../../common';
import {fromQueryString, toQueryString} from '../../utils';

import TeamDetail from './TeamDetail';
import TeamDetailForm from './TeamDetailForm';

export default function TeamDetailPage(props) {
  // order of these matter!
  const location = useLocation();
  const history = useHistory();
  const params = useParams();
  const [periodData, setPeriodData] = useState({
    season: undefined,
    seasons: [],
    startWeek: undefined,
    endWeek: undefined,
    weeks: [],
  });
  const [team, setTeam] = useState({});
  const [teams, setTeams] = useState([]);
  const [teamDetail, setTeamDetail] = useState({
    startWeek: undefined,
    endWeek: undefined,
    season: undefined,
    teamDetail: {
      results: [],
      pointSummary: {
        pointDifference: {},
        pointTotal: {},
      },
      parlaySummary: {},
    },
  });
  const [isLoading, setIsLoading] = useState(true);
  // const [error, setError] = useState(false);
  // init effect
  useEffect(() => {
    async function init() {
      setIsLoading(true);
      const search = fromQueryString(location.search);
      // unrelated: perhaps make api return seasons + weeks in one call?
      const nextSeasons = (await getSeasons()).data;
      let nextSeason;
      let nextStartWeek;
      let nextEndWeek;
      let nextWeeks;

      // check location for existing season/week combo if none, set defaults
      if (nextSeasons.includes(parseInt(search.season))) {
        nextSeason = parseInt(search.season);
        nextWeeks = (await getCompletedWeeksInSeason(nextSeason)).data;
        if (nextWeeks.includes(parseInt(search.startWeek))) {
          // got season, got start week
          nextStartWeek = parseInt(search.startWeek);
        } else {
          // got season, no start week
          nextStartWeek = nextWeeks[0];
        }
        if (nextWeeks.includes(parseInt(search.endWeek))) {
          // got season, got end week
          nextEndWeek = parseInt(search.endWeek);
        } else {
          // got season, no end week
          nextEndWeek = nextWeeks[nextWeeks.length - 1];
        }
      } else {
        // no season, no week
        nextSeason = nextSeasons[0];
        nextWeeks = (await getCompletedWeeksInSeason(nextSeason)).data;
        nextStartWeek = nextWeeks[0];
        nextEndWeek = nextWeeks[nextWeeks.length - 1];
      }
      const nextTeams = (await getTeamsForSeason(nextSeason)).data;
      let nextTeam;
      if (params.teamId) {
        nextTeam = nextTeams.find((x) => x.id === parseInt(params.teamId));
      } else {
        nextTeam = nextTeams[0];
      }
      const nextTeamDetail = (
        await getTeamDetail({
          teamId: nextTeam.id,
          season: nextSeason,
          startWeek: nextStartWeek,
          endWeek: nextEndWeek,
        })
      ).data;

      // call setters
      setPeriodData({
        seasons: nextSeasons,
        season: nextSeason,
        weeks: nextWeeks,
        startWeek: nextStartWeek,
        endWeek: nextEndWeek,
      });
      setTeams(nextTeams);
      setTeam(nextTeam);
      setTeamDetail({
        startWeek: nextStartWeek,
        endWeek: nextEndWeek,
        season: nextSeason,
        teamDetail: nextTeamDetail,
      });
      setIsLoading(false);
    }
    init();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // location or params change effect
  useEffect(() => {
    // only run if team id param is non null
    if (!params.teamId) {
      return;
    }
    async function update() {
      setIsLoading(true);
      const search = fromQueryString(location.search);

      if (periodData.seasons.includes(parseInt(search.season))) {
        const nextSeason = parseInt(search.season);
        const nextWeeks = (await getCompletedWeeksInSeason(nextSeason)).data;
        const nextTeams = (await getTeamsForSeason(nextSeason)).data;
        const nextTeam = nextTeams.find(
          (x) => x.id === parseInt(params.teamId),
        );
        let nextStartWeek;
        let nextEndWeek;
        if (nextWeeks.includes(parseInt(search.startWeek))) {
          nextStartWeek = parseInt(search.startWeek);
        } else {
          nextStartWeek = nextWeeks[0];
        }
        if (nextWeeks.includes(parseInt(search.endWeek))) {
          nextEndWeek = parseInt(search.endWeek);
        } else {
          nextEndWeek = nextWeeks[nextWeeks.length - 1];
        }

        const nextTeamDetail = (
          await getTeamDetail({
            teamId: nextTeam.id,
            season: nextSeason,
            startWeek: nextStartWeek,
            endWeek: nextEndWeek,
          })
        ).data;

        setPeriodData({
          ...periodData,
          season: nextSeason,
          weeks: nextWeeks,
          startWeek: nextStartWeek,
          endWeek: nextEndWeek,
        });
        setTeams(nextTeams);
        setTeam(nextTeam);
        setTeamDetail({
          startWeek: nextStartWeek,
          endWeek: nextEndWeek,
          season: nextSeason,
          teamDetail: nextTeamDetail,
        });
        setIsLoading(false);
      }
    }
    update();
  }, [location, params]); // eslint-disable-line react-hooks/exhaustive-deps

  // form input change handlers
  async function onSeasonChange(e) {
    const nextSeason = parseInt(e.target.value);
    const nextWeeks = (await getCompletedWeeksInSeason(nextSeason)).data;
    const nextStartWeek = nextWeeks[0];
    const nextEndWeek = nextWeeks[nextWeeks.length - 1];

    setPeriodData({
      ...periodData,
      season: nextSeason,
      weeks: nextWeeks,
      startWeek: nextStartWeek,
      endWeek: nextEndWeek,
    });
  }

  function onStartWeekChange(e) {
    const nextStartWeek = parseInt(e.target.value);
    setPeriodData({
      ...periodData,
      startWeek: nextStartWeek,
    });
  }

  function onEndWeekChange(e) {
    const nextEndWeek = parseInt(e.target.value);
    setPeriodData({
      ...periodData,
      endWeek: nextEndWeek,
    });
  }

  function onTeamChange(e) {
    const nextTeamId = parseInt(e.target.value);
    const nextTeam = teams.find((elem) => elem.id === nextTeamId);
    setTeam(nextTeam);
  }

  function onSubmit(e) {
    e.preventDefault();
    const nextQueryString = toQueryString({
      season: periodData.season,
      startWeek: periodData.startWeek,
      endWeek: periodData.endWeek,
    });
    const nextLocation = `/teams/${team.id}${nextQueryString}`;
    history.push(nextLocation);
  }

  return (
    <div className='container'>
      <div className='level'>
        <div className='level-left'>
          <TeamDetailForm
            {...periodData}
            team={team}
            teams={teams}
            onSeasonChange={onSeasonChange}
            onStartWeekChange={onStartWeekChange}
            onEndWeekChange={onEndWeekChange}
            onTeamChange={onTeamChange}
            onSubmit={onSubmit}
          />
        </div>
      </div>
      {(
        !teamDetail.teamDetail.teamName ||
        !teamDetail.startWeek ||
        !teamDetail.endWeek ||
        !teamDetail.season ||
        isLoading) ? (
          <LoadingIndicator />
        ) : (
          <PageContainer
            title={teamDetail.teamDetail.teamName}
            subtitle={`Weeks ${teamDetail.startWeek} - ${teamDetail.endWeek}, ${teamDetail.season}`}>
            <TeamDetail {...teamDetail} />
          </PageContainer>
        )}
    </div>
  );
}
