import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {includes, some, trim} from 'lodash';

import {Card, CardContent, CardHeader} from '../../common';
import {PARLAY_TYPES} from '../../constants';
import {toQueryString} from '../../utils';

function formatSpread(value) {
  if (!value) {
    return '';
  }
  if (value > 0) {
    return `+${value}`;
  }
  return `${value}`;
}

function GameResultsTable({gameResults, teamFilterValues}) {
  const periodSearch = toQueryString({
    season: gameResults.season,
    startWeek: 1, // implied start week
    endWeek: gameResults.week,
  });

  const filteredResults = gameResults.results.filter(
    ({homeTeam, awayTeam}) => {
      return some(
        trim(teamFilterValues, ' ,').split(','),
        (teamFilterValue) => {
          teamFilterValue = teamFilterValue.trim().toLowerCase();
          return (
            includes(homeTeam.teamShortName.toLowerCase(), teamFilterValue) ||
            includes(awayTeam.teamShortName.toLowerCase(), teamFilterValue)
          );
        },
      );
    },
  );

  const gameRows = filteredResults.map(
    ({homeTeam, awayTeam, gameSpreads, isComplete}) => (
      <React.Fragment key={`${homeTeam['teamName']}-${awayTeam['teamName']}`}>
        <tr>
          <th className='is-flex is-align-items-center'>
            <span className='is-size-7 has-text-primary mr-2'>H:</span>
            <Link
              className='has-text-primary'
              to={{
                pathname: `/teams/${homeTeam.teamId}`,
                search: periodSearch,
              }}>
              {homeTeam.teamShortName}
            </Link>
          </th>
          <td className={isComplete ? 'has-text-weight-medium' : undefined}>
            {homeTeam.points}
          </td>
          {PARLAY_TYPES.map((parlayType) => (
            <td
              key={`homeTeam-${parlayType}`}
              className={
                homeTeam.teamSpreads[parlayType] + homeTeam.points >
                awayTeam.points
                  ? 'has-covered'
                  : 'has-not-covered'
              }>
              {formatSpread(homeTeam.teamSpreads[parlayType])}
            </td>
          ))}
          {PARLAY_TYPES.filter((x) => x !== 'reverseTeaser').map(
            (parlayType) => (
              <React.Fragment key={parlayType}>
                <td
                  rowSpan='2'
                  className={
                    homeTeam.points + awayTeam.points >
                    gameSpreads[parlayType]['over']
                      ? 'has-covered'
                      : 'has-not-covered'
                  }>
                  {gameSpreads[parlayType]['over']}
                </td>
                <td
                  rowSpan='2'
                  className={
                    homeTeam.points + awayTeam.points <
                    gameSpreads[parlayType]['under']
                      ? 'has-covered'
                      : 'has-not-covered'
                  }>
                  {gameSpreads[parlayType]['under']}
                </td>
              </React.Fragment>
            ),
          )}
        </tr>
        <tr>
          <th className='is-flex is-align-items-center'>
            <span className='is-size-7 has-text-primary mr-2'>A:</span>
            <Link
              className='has-text-primary'
              to={{
                pathname: `/teams/${awayTeam.teamId}`,
                search: periodSearch,
              }}>
              {awayTeam.teamShortName}
            </Link>
          </th>
          <td className={isComplete ? 'has-text-weight-medium' : undefined}>
            {awayTeam.points}
          </td>
          {PARLAY_TYPES.map((parlayType) => (
            <td
              key={`awayTeam-${parlayType}`}
              className={
                awayTeam.teamSpreads[parlayType] + awayTeam.points >
                homeTeam.points
                  ? 'has-covered'
                  : 'has-not-covered'
              }>
              {formatSpread(awayTeam.teamSpreads[parlayType])}
            </td>
          ))}
        </tr>
      </React.Fragment>
    ),
  );
  return (
    <div className='table-container'>
      <table className='table is-fullwidth table-pairs-striped'>
        <thead>
          <tr>
            <th rowSpan='2'></th>
            <th rowSpan='2'>Points</th>
            <th rowSpan='2'>Regular</th>
            <th rowSpan='2'>Teaser</th>
            <th rowSpan='2'>Super Teaser</th>
            <th rowSpan='2'>Reverse Teaser</th>
            <th colSpan='2'>Regular</th>
            <th colSpan='2'>Teaser</th>
            <th colSpan='2'>Super Teaser</th>
          </tr>
          <tr>
            <th>Over</th>
            <th>Under</th>
            <th>Over</th>
            <th>Under</th>
            <th>Over</th>
            <th>Under</th>
          </tr>
        </thead>
        <tbody>{gameRows}</tbody>
      </table>
    </div>
  );
}

export default function GameResults({gameResults}) {
  const [teamFilterValues, setTeamFilterValues] = useState('');

  async function onTeamFiltersChange(event) {
    setTeamFilterValues(event.target.value);
    await new Promise((resolve) => setTimeout(resolve, 100));
  }

  return (
    <Card title='Game Results'>
      <CardHeader>Game Results</CardHeader>
      <CardContent>
        <div className='columns'>
          <div className='column is-half'>
            <div className='field'>
              <div className='control'>
                <input
                  className='input is-primary mt-3'
                  type='text'
                  value={teamFilterValues}
                  onChange={onTeamFiltersChange}
                  placeholder='Team Filters (e.g., "Eagles, Cowboys")'
                />
              </div>
            </div>
          </div>
        </div>
        <GameResultsTable
          gameResults={gameResults}
          teamFilterValues={teamFilterValues}
        />
      </CardContent>
    </Card>
  );
}
