import {cartesianPairs, removeFirst} from './utils';

export const PARLAY_TYPES = [
  'regular',
  'teaser',
  'superTeaser',
  'reverseTeaser',
];

export const GAME_SPREAD_TYPES = ['over', 'under'];

export const TYPE_PAIRS = [
  ...cartesianPairs(
    removeFirst(PARLAY_TYPES, 'reverseTeaser'),
    GAME_SPREAD_TYPES,
  ),
];

// const palette = {
//   dark: '#00a78a',
//   light:
// }
