import React from 'react';

import {Card, CardContent, CardHeader} from '../../common';

function SpreadCoverCell({covered, observations}) {
  return (
    <td>
      <span>{parseFloat(covered / observations).toFixed(2)}</span>
      <span className='has-text-grey-light'> (n={observations})</span>
    </td>
  );
}

export default function ParlaySummary({
  regular,
  teaser,
  superTeaser,
  reverseTeaser,
}) {
  return (
    <Card title='Parlay Summary'>
      <CardHeader>Parlay Summary</CardHeader>
      <CardContent>
        <div className='table-container'>
          <table className='table is-striped is-fullwidth'>
            <thead>
              <tr>
                <th></th>
                <th>Spread Cover %</th>
                <th>Over Cover %</th>
                <th>Under Cover %</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th className='has-text-left'>Regular</th>
                <SpreadCoverCell {...regular.spread} />
                <SpreadCoverCell {...regular.over} />
                <SpreadCoverCell {...regular.under} />
              </tr>
              <tr>
                <th className='has-text-left'>Teaser</th>
                <SpreadCoverCell {...teaser.spread} />
                <SpreadCoverCell {...teaser.over} />
                <SpreadCoverCell {...teaser.under} />
              </tr>
              <tr>
                <th className='has-text-left'>Super Teaser</th>
                <SpreadCoverCell {...superTeaser.spread} />
                <SpreadCoverCell {...superTeaser.over} />
                <SpreadCoverCell {...superTeaser.under} />
              </tr>
              <tr>
                <th className='has-text-left'>Reverse Teaser</th>
                <SpreadCoverCell {...reverseTeaser.spread} />
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </CardContent>
    </Card>
  );
}
