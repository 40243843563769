import React, {useEffect, useState} from 'react';
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';

import {getSeasons, getCompletedWeeksInSeason} from '../../api';
import {LoadingIndicator, PageContainer} from '../../common';
import {fromQueryString, toQueryString} from '../../utils';

import * as Ranks from './Ranks';
import TeamRanksForm from './TeamRanksForm';
import TeamRanksGrid from './TeamRanksGrid';

export default function TeamRanksPage(props) {
  // order of these matter!
  const location = useLocation();
  const history = useHistory();
  const {path} = useRouteMatch();

  const [periodData, setPeriodData] = useState({
    seasons: [],
    season: undefined,
    weeks: [],
    startWeek: undefined,
    endWeek: undefined,
  });
  const [rankProps, setRankProps] = useState({
    season: undefined,
    startWeek: undefined,
    endWeek: undefined,
    type: undefined,
    gameSpreadType: undefined,
  });

  const [isLoading, setIsLoading] = useState(true);
  // const [error, setIsError] = useState({});

  // init effect
  useEffect(() => {
    async function init() {
      setIsLoading(true);
      const search = fromQueryString(location.search);
      // unrelated: perhaps make api return seasons + weeks in one call?
      const nextSeasons = (await getSeasons()).data;
      let nextSeason;
      let nextStartWeek;
      let nextEndWeek;
      let nextWeeks;

      // check location for existing season/week combo if none, set defaults
      if (nextSeasons.includes(parseInt(search.season))) {
        nextSeason = parseInt(search.season);
        nextWeeks = (await getCompletedWeeksInSeason(nextSeason)).data;
        if (nextWeeks.includes(parseInt(search.startWeek))) {
          // got season, got week
          nextStartWeek = parseInt(search.startWeek);
        } else {
          // got season, no week
          nextStartWeek = nextWeeks[0];
        }
        if (nextWeeks.includes(parseInt(search.endWeek))) {
          // got season, got week
          nextEndWeek = parseInt(search.endWeek);
        } else {
          // got season, no week
          nextEndWeek = nextWeeks[nextWeeks.length - 1];
        }
      } else {
        // no season, no week
        nextSeason = nextSeasons[0];
        nextWeeks = (await getCompletedWeeksInSeason(nextSeason)).data;
        nextStartWeek = nextWeeks[0];
        nextEndWeek = nextWeeks[nextWeeks.length - 1];
      }

      // call setters
      setPeriodData({
        seasons: nextSeasons,
        season: nextSeason,
        weeks: nextWeeks,
        startWeek: nextStartWeek,
        endWeek: nextEndWeek,
      });
      setRankProps({
        startWeek: nextStartWeek,
        endWeek: nextEndWeek,
        season: nextSeason,
        type: search.type,
        gameSpreadType: search.gameSpreadType,
      });
      setIsLoading(false);
    }
    init();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // location change effect
  useEffect(() => {
    async function update() {
      setIsLoading(true);
      const search = fromQueryString(location.search);

      if (periodData.seasons.includes(parseInt(search.season))) {
        const nextSeason = parseInt(search.season);
        const nextWeeks = (await getCompletedWeeksInSeason(nextSeason)).data;
        let nextStartWeek;
        let nextEndWeek;

        if (nextWeeks.includes(parseInt(search.startWeek))) {
          nextStartWeek = parseInt(search.startWeek);
        } else {
          nextStartWeek = nextWeeks[0];
        }
        if (nextWeeks.includes(parseInt(search.endWeek))) {
          nextEndWeek = parseInt(search.endWeek);
        } else {
          nextEndWeek = nextWeeks[nextWeeks.length - 1];
        }
        setPeriodData({
          ...periodData,
          season: nextSeason,
          weeks: nextWeeks,
          startWeek: nextStartWeek,
          endWeek: nextEndWeek,
        });
        setRankProps({
          startWeek: nextStartWeek,
          endWeek: nextEndWeek,
          season: nextSeason,
          type: search.type,
          gameSpreadType: search.gameSpreadType,
        });
      }
      setIsLoading(false);
    }
    update();
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  // form input change handlers
  async function onSeasonChange(e) {
    const nextSeason = parseInt(e.target.value);
    const nextWeeks = (await getCompletedWeeksInSeason(nextSeason)).data;
    const nextStartWeek = nextWeeks[0];
    const nextEndWeek = nextWeeks[nextWeeks.length - 1];

    setPeriodData({
      ...periodData,
      season: nextSeason,
      weeks: nextWeeks,
      startWeek: nextStartWeek,
      endWeek: nextEndWeek,
    });
  }

  function onStartWeekChange(e) {
    const nextStartWeek = parseInt(e.target.value);
    setPeriodData({
      ...periodData,
      startWeek: nextStartWeek,
    });
  }

  function onEndWeekChange(e) {
    const nextEndWeek = parseInt(e.target.value);
    setPeriodData({
      ...periodData,
      endWeek: nextEndWeek,
    });
  }

  // form submit handler
  function onSubmit(e) {
    e.preventDefault();
    const {pathname} = history.location;
    const search = fromQueryString(location.search);
    const nextQuery = {
      season: periodData.season,
      startWeek: periodData.startWeek,
      endWeek: periodData.endWeek,
    };
    if (search.type) {
      nextQuery['type'] = search.type;
    }
    if (search.gameSpreadType) {
      nextQuery['gameSpreadType'] = search.gameSpreadType;
    }
    const nextQueryString = toQueryString(nextQuery);
    const nextLocation = `${pathname}${nextQueryString}`;
    history.push(nextLocation);
  }

  function shouldLoad() {
    return (
      !rankProps.startWeek ||
      !rankProps.endWeek ||
      !rankProps.season ||
      isLoading
    );
  }
  return (
    <div className='container'>
      <div className='level'>
        <div className='level-left'>
          <TeamRanksForm
            {...periodData}
            onSeasonChange={onSeasonChange}
            onStartWeekChange={onStartWeekChange}
            onEndWeekChange={onEndWeekChange}
            onSubmit={onSubmit}
          />
        </div>
      </div>
      { shouldLoad() ? (
        <LoadingIndicator />
      ) : (
        <PageContainer
          title={`Weeks ${rankProps.startWeek} - ${rankProps.endWeek}, ${rankProps.season}`}>
          <Switch>
            <Route exact path={path}>
              <TeamRanksGrid {...rankProps} />
            </Route>
            <Route exact path={`${path}/point-difference`}>
              <Ranks.TeamPointDifferenceRanks {...rankProps} />
            </Route>
            <Route exact path={`${path}/point-total`}>
              <Ranks.TeamPointTotalRanks {...rankProps} />
            </Route>
            <Route exact path={`${path}/parlay-team-spread-margin`}>
              {rankProps.type ? (
                <Ranks.ParlayTeamSpreadMarginRanks {...rankProps} />
              ) : (
                <LoadingIndicator />
              )}
            </Route>
            <Route exact path={`${path}/parlay-team-spread-cover`}>
              {rankProps.type ? (
                <Ranks.ParlayTeamSpreadCoverRanks {...rankProps} />
              ) : (
                <LoadingIndicator />
              )}
            </Route>
            <Route exact path={`${path}/parlay-game-spread-margin`}>
              {rankProps.type && rankProps.gameSpreadType ? (
                <Ranks.ParlayGameSpreadMarginRanks {...rankProps} />
              ) : (
                <LoadingIndicator />
              )}
            </Route>
            <Route exact path={`${path}/parlay-game-spread-cover`}>
              {rankProps.type && rankProps.gameSpreadType ? (
                <Ranks.ParlayGameSpreadCoverRanks {...rankProps} />
              ) : (
                <LoadingIndicator />
              )}
            </Route>
          </Switch>
        </PageContainer>
      )}
    </div>
  );
}
