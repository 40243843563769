import React from 'react';
import {TwitterTimelineEmbed} from 'react-twitter-embed';

import ParlaySummary from './ParlaySummary';
import PointSummary from './PointSummary';
import TeamResults from './TeamResults';

export default function TeamDetail(props) {
  const {endWeek, season, startWeek, teamDetail} = props;
  // const outcomeCounts = _.countBy(team.results.map(getOutcome));
  // const record = [
  //   ['won', 'W'],
  //   ['lost', 'L'],
  //   ['tied', 'T'],
  // ]
  //   .map((outcome) => `${_.get(outcomeCounts, outcome[0], 0)}${outcome[1]}`)
  //   .join('-');
  return (
    <div className='columns'>
      <div className='column is-one-third-widescreen is-half-desktop is-half-tablet'>
        <TeamResults
          results={teamDetail.results}
          season={season}
          startWeek={startWeek}
          endWeek={endWeek}
        />
      </div>
      <div className='column is-two-thirds-widescreen is-half-desktop is-half-tablet'>
        <div className='columns is-multiline'>
          <div className='column is-full'>
            <PointSummary
              pointDifference={teamDetail.pointSummary.pointDifference}
              pointTotal={teamDetail.pointSummary.pointTotal}
            />
          </div>
          <div className='column is-full'>
            <ParlaySummary {...teamDetail.parlaySummary} />
          </div>
          {teamDetail.twitterHandle && (
            <div className='column is-full'>
              <TwitterTimelineEmbed
                key={teamDetail.twitterHandle}
                sourceType='profile'
                screenName={teamDetail.twitterHandle}
                noHeader
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
