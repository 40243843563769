import React from 'react';

import {SelectField, SubmitButtonField} from '../../common';
import {makeOption} from '../../utils';

export default function GamesForm(props) {
  const seasonOptions = props.seasons.map(makeOption);
  const weekOptions = props.weeks.map(makeOption);
  return (
    <form onSubmit={props.onSubmit}>
      <div className='field is-horizontal'>
        <div className='field-body'>
          <SelectField
            label='Season'
            id='season'
            value={props.season}
            onChange={props.onSeasonChange}>
            {seasonOptions}
          </SelectField>
          <SelectField
            label='Week'
            id='week'
            value={props.week}
            onChange={props.onWeekChange}>
            {weekOptions}
          </SelectField>
          <SubmitButtonField text='Go' />
        </div>
      </div>
    </form>
  );
}
