import React, {useEffect} from 'react';

export function LoadingIndicator(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <progress className='progress is-small is-primary' max='100'>
      15%
    </progress>
  );
}

export function ErrorIndicator(props) {
  return <h1>Error!</h1>;
}

export function PageContainer(props) {
  return (
    <div className='columns is-multiline'>
      {props.title && (
        <div className='column is-full'>
          <div className='hero is-primary is-light'>
            <div className='hero-body'>
              <h1 className='title'>{props.title}</h1>
              {props.subtitle && <h2 className='subtitle'>{props.subtitle}</h2>}
            </div>
          </div>
        </div>
      )}
      <div className='column is-full'>{props.children}</div>
    </div>
  );
}

export function Card(props) {
  return (
    <div className='card' {...props}>
      {props.children}
    </div>
  );
}

export function CardHeader(props) {
  return (
    <div className='card-header'>
      <h1 className='card-header-title has-fontvar-allcaps'>
        {props.children}
      </h1>
    </div>
  );
}

export function CardContent(props) {
  return <div className='card-content'>{props.children}</div>;
}

export function CardFooter(props) {
  return <div className='card-footer'>{props.children}</div>;
}

export function CardFooterItem(props) {
  return <p className='card-footer-item'>{props.children}</p>;
}

export function SelectOption({value, key, text}) {
  return (
    <option value={value} key={key || value}>
      {text || value}
    </option>
  );
}

export function SelectField({id, label, value, onChange, children}) {
  return (
    <div className='field'>
      <label className='label' htmlFor={id}>
        {label}
      </label>
      <div className='control'>
        <div className='select is-primary'>
          <select id={id} value={value} onChange={onChange}>
            {children}
          </select>
        </div>
      </div>
    </div>
  );
}

export function DollarValueField({id, label, value, onChange}) {
  return (
    <div className='field'>
      <label className='label' htmlFor={id}>
        {label}
      </label>
      <div className='field has-addons'>
        <div className='control'>
          <a className='button is-primary'>$</a>
        </div>
        <div className='control'>
          <input
            className='input is-primary'
            id={id}
            type='text'
            onChange={onChange}
            value={value}
          />
        </div>
        <div className='control'>
          <a className='button is-static'>.00</a>
        </div>
      </div>
    </div>
  );
}

export function SubmitButtonField({text}) {
  return (
    <div className='field is-flex'>
      <div className='control is-flex is-flex-end'>
        <button
          className='button is-primary has-text-weight-bold'
          type='submit'>
          {text}
        </button>
      </div>
    </div>
  );
}
